<template>
    <div> 
    <div class="details-view mb-36">

        <InlineNotification Heading="GigPig House Rules" class="p-0 m-0">
            <template slot="body">
                <p class="text-justify">
                    Welcome to GigPig, the online booking platform where artists can connect with venues and event organisers to book gigs. 
                    To ensure a positive and harmonious experience for all users, we have established the following house rules and guidelines.
                </p>
            </template>
        </InlineNotification>
        <div class="max-w-4xl">
            <Accordian heading="1) Cancellation" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                Please give as much notice as possible if you wish to cancel the musician, DJ or entertainer. If it is within 24 hours of the booking, then we would advise you to either pay the artist 50% of 
                                the fee or book them for a future gig to show goodwill. 
                                Consistent last minute cancellations will reflect on you badly within the artist community and may result in artists declining to play your venue.
                            </p>
                        </template>
                    </InlineNotification>                
                </template>
            </Accordian>

            <Accordian heading="2) Gig instructions" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                When creating your booking, please ensure the instructions and details are adequate to make it easier for artists to fully understand the gig offer. This should include the equipment currently on site, 
                                if it is in good working order, what equipment the artists need to provide, the genre you want the artist to play and the time you want the artist at your venue to set up and perform. 
                            </p>
                        </template>
                    </InlineNotification>  
                </template>
            </Accordian>


            <Accordian heading="3) Book in Advance" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                To give yourself the best opportunity to book the artists that you prefer to perform at your venue, book well in advance of your scheduled date.
                            </p>
                        </template>
                    </InlineNotification>
                    
                </template>
            </Accordian>

            <Accordian heading="4) Respect" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                Please treat all musicians, DJs and entertainers who are performing at your venue with respect, courtesy and professionalism. 
                            </p>
                        </template>
                    </InlineNotification>
                </template>
            </Accordian>

            <Accordian heading="5) Payment Terms" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                Clearly state the payment terms of each gig, in as much detail as possible. This should include whether you will pay the artist individually or in a batch at the end of the month.
                            </p>
                        </template>
                    </InlineNotification>
                </template>
            </Accordian>
        </div>
    </div>
</div>

</template>

<script>
import InlineNotification from '../../components/notifications/inline.vue'
import Accordian from '../../components/accordian/Accordian.vue' 

export default
{
    name:"VenueHouseRules",
    components: {
        InlineNotification,
        Accordian
    }
}
</script>